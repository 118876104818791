import React, {Fragment} from "react";
import {ITruck} from "../../../types";

interface Props {
    elements: ITruck[],
    setZoomToMarker: (id: number) => void,
}

export default function MapTrucksList(props: Props) {
    const {elements} = props;

    const locations = elements.map((truck: ITruck) => {
        const loc = truck.etransport ? truck.etransport.locality : '-';
        return loc.split(",")[0];
    }).filter((value, index, self) => {
        return self.indexOf(value) === index;
    }).sort();

    const locate = (truck: ITruck) => {
        props.setZoomToMarker(truck.id);
    }

    return (
        <div className={"map-trucks-list"}>
            <div className={"inner"}>
                {locations.map((country: string) => {
                    return (
                        <Fragment key={"c-" + country}>
                            <div className={"title"}>{country}</div>
                            {elements.filter((truck: ITruck) => {
                                const loc = truck.etransport ? truck.etransport.locality : '-';
                                return loc.indexOf(country) === 0;
                            }).map((truck: ITruck, index: number) => {
                                const loc = truck.etransport ? truck.etransport.locality : '-';
                                const location = loc.split(", ").filter((_, i) => i !== 0).join(", ");

                                const title = truck.number + " (" + truck.driverName + ")"

                                return (
                                    <div className={"item"} key={'tr-' + truck.id} onClick={() => locate(truck)}>
                                        <div className={"subtitle"} title={title}>{title}</div>
                                        <div className={"address"} title={truck.etransport?.timestamp}>{location}</div>
                                        <span
                                            className={"status " + truck.etransport?.status + " " + (truck.etransport?.missSignal ? " miss-signal" : "")}/>
                                    </div>
                                )
                            })}
                        </Fragment>
                    )
                })}

            </div>
        </div>
    )
}

