import React, {Fragment, useEffect, useState} from "react";
import {useHistory, useParams} from 'react-router-dom'
import {NaeLayout} from 'nae-react-core-styles'
import {toast} from "react-toastify";
import ApiRest from "../../service/ApiRest";
import {Button, ButtonGroup, Card, Col, Container, Row} from "react-bootstrap";
import {NaeRestRemoveBtn} from "nae-react-rest";
import {
    filterChangeDefElement,
    inventoryDefElement,
    oilChangeDefElement,
    photoAlbumDefElement,
    truckDefElement, tyreCheckDefElement
} from "../../types";
import TruckForm from "../Forms/TruckForm";
import InventoryPage from "./Inventory/InventoryPage";
import InventoriesListCard from "./Inventory/InventoriesListCard";
import NaeModal from "../Modals/NaeModal";
import {NaeRestProvider, useNaeRest} from "../NaeRestProvider";
import TyreCheckPage from "./TyreCheck/TyreCheckPage";
import PhotoAlbumPage from "./PhotoAlbum/PhotoAlbumPage";
import OilChangePage from "./OilChange/OilChangePage";
import FilterChangePage from "./FilterChange/FilterChangePage";
import FilterChangeListCard from "./FilterChange/FilterChangeListCard";
import OilChangeListCard from "./OilChange/OilChangeListCard";
import TyreCheckListCard from "./TyreCheck/TyreCheckListCard";
import PhotoAlbumListCard from "./PhotoAlbum/PhotoAlbumListCard";

// @ts-ignore
import { createClient } from "webdav/web";


const moduleName = 'truck';

interface ParamTypes {
    id: string
}

const keysToSave = [
    'number',
    'mark',
    'technicalInspectionValid',
    'licenceValid',
    'tachographCheck',
    'insuranceKzValid',
    'insuranceEuValid',
    'batteryMark',
    'batteryPurchaseDate',
    'trailerId',
    'driverId',
    'etransportTruckId',
    'files'
]

export default function TruckPage() {
    const history = useHistory();

    const {element, id, restDoSaveWithKeys} = useNaeRest();

    const [newInventoryPopup, setNewInventoryPopup] = useState(false)
    const [newOilChangePopup, setNewOilChangePopup] = useState(false)
    const [newFilterChangePopup, setNewFilterChangePopup] = useState(false)
    const [newPhotoAlbumPopup, setNewPhotoAlbumPopup] = useState(false)
    const [newTyreCheckPopup, setNewTyreCheckPopup] = useState(false)

    const form = (element.id > 0 || id === 'new') ?
        <Fragment>
            <TruckForm saveData={(extra?: any) => restDoSaveWithKeys(keysToSave, extra)}/>

            <NaeModal isOpen={newInventoryPopup} onClose={() => setNewInventoryPopup(false)}>
                <NaeRestProvider id={'new'} moduleName={'inventory'} defElement={{...inventoryDefElement, mileage: element.mileage}}
                                 onSave={() => setNewInventoryPopup(false)}>
                    <InventoryPage truckId={element.id}/>
                </NaeRestProvider>
            </NaeModal>

            <NaeModal isOpen={newFilterChangePopup} onClose={() => setNewFilterChangePopup(false)}>
                <NaeRestProvider id={'new'} moduleName={'filter-change'} defElement={{...filterChangeDefElement, mileage: element.mileage}}
                                 onSave={() => setNewFilterChangePopup(false)}>
                    <FilterChangePage truckId={element.id}/>
                </NaeRestProvider>
            </NaeModal>

            <NaeModal isOpen={newOilChangePopup} onClose={() => setNewOilChangePopup(false)}>
                <NaeRestProvider id={'new'} moduleName={'oil-change'} defElement={{...oilChangeDefElement, mileage: element.mileage}}
                                 onSave={() => setNewOilChangePopup(false)}>
                    <OilChangePage truckId={element.id}/>
                </NaeRestProvider>
            </NaeModal>

            <NaeModal isOpen={newPhotoAlbumPopup} onClose={() => setNewPhotoAlbumPopup(false)}>
                <NaeRestProvider id={'new'} moduleName={'photo-album'} defElement={{...photoAlbumDefElement, mileage: element.mileage}}
                                 onSave={() => setNewPhotoAlbumPopup(false)}>
                    <PhotoAlbumPage truckId={element.id}/>
                </NaeRestProvider>
            </NaeModal>

            <NaeModal className={"tyres-modal"} isOpen={newTyreCheckPopup} onClose={() => setNewTyreCheckPopup(false)}>
                <NaeRestProvider id={'new'} moduleName={'tyre-check'} defElement={{...tyreCheckDefElement, mileage: element.mileage}}
                                 onSave={() => setNewTyreCheckPopup(false)}>
                    <TyreCheckPage truckId={element.id}/>
                </NaeRestProvider>
            </NaeModal>

        </Fragment>
        : <Fragment/>
    ;

    const rightComponent = (
        element.id > 0 ?
            <Fragment>
                <Card>
                    <Card.Body>
                        <NaeRestRemoveBtn lang={"lt"} path={'/trucks'} module={moduleName} id={element.id}/>


                    </Card.Body>
                </Card>
                {element.id > 0 &&
                <Card>
                    <Card.Body>

                        <ButtonGroup vertical={true}>

                            <Button variant={"secondary"} onClick={() => setNewTyreCheckPopup(true)}>
                                Padangų tikrinimas
                            </Button>
                            <Button variant={"secondary"} onClick={() => setNewFilterChangePopup(true)}>
                                Filtrų keitimas
                            </Button>
                            <Button variant={"secondary"} onClick={() => setNewOilChangePopup(true)}>
                                Tepalų keitimas
                            </Button>

                            <Button variant={"secondary"} onClick={() => setNewInventoryPopup(true)}>
                                Inventorizacija
                            </Button>
                            <Button variant={"secondary"} onClick={() => setNewPhotoAlbumPopup(true)}>
                                Fotografavimas
                            </Button>
                        </ButtonGroup>
                    </Card.Body>
                </Card>
                }
            </Fragment>
            :
            <Fragment/>
    );

    return (<Fragment>

            <NaeLayout.NaePageViewLayout leftComponent={form} rightComponent={rightComponent}/>
            {element.id > 0 &&
            <Fragment>
                <Row>
                    <Col><TyreCheckListCard options={{truck: element.id}}/></Col>

                </Row>
                <Row>
                    <Col>
                        <FilterChangeListCard options={{truck: element.id}}/>
                    </Col>
                    <Col>
                        <OilChangeListCard options={{truck: element.id}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InventoriesListCard options={{truck: element.id}}/>
                    </Col>
                    <Col>
                        <PhotoAlbumListCard options={{truck: element.id}}/>
                    </Col>
                </Row>
            </Fragment>
            }

        </Fragment>
    )
}
