const modalCustomStyles = {
    // content: {
    //     // top: '50px',
    //     // left: 'auto',
    //     // right: 'auto',
    //     // bottom: 'auto',
    //     // marginRight: '-50%',
    //     // marginLeft: '50%',
    //     // margin: '0 auto',
    //     // transform             : 'translate(-50%, -50%)',
    //     transform: 'none',
    //     inset: '0',
    //     position: 'initial'
    // }
};

export {modalCustomStyles}
