import React, {Fragment, useEffect, useState, useRef} from 'react';
import {MapContainer, TileLayer} from 'react-leaflet'
import ApiRest from "../../../service/ApiRest";
import {IEtransportasTruck, ITruck} from "../../../types";
import MapsMarkers from "./MapsMarkers";
import {Col, Row} from "react-bootstrap";
import MapTrucksList from "./MapTrucksList";

const moduleName = 'truck'

export default function MapsPage() {

    const [zoomToMarker, setZoomToMarker] = useState(0);

    const [elements, setElements] = useState<ITruck[]>([])

    const getData = () => {
        ApiRest.getElements(moduleName).then(res => {
            setElements(res);
        })
    }

    useEffect(getData, []);

    return (
        <Fragment>
            <div className={"nae-map"}>
                <Row>
                    <Col>
                        <MapContainer className={"nae-map-container"} zoom={13} scrollWheelZoom={true}>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <MapsMarkers zoomToMarker={zoomToMarker} elements={elements}/>
                        </MapContainer>
                    </Col>
                    <Col sm={2}>
                        <MapTrucksList setZoomToMarker={setZoomToMarker} elements={elements}/>
                    </Col>
                </Row>

            </div>
        </Fragment>
    )
}
