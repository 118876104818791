import {NaeRouter} from "nae-react-core-styles";
import React, {useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {ITrailer} from "../../types";
import DateField from "./Fields/DateField";
import {useNaeRest} from "../NaeRestProvider";
import FileComponent from "./File/FileComponent";
import ApiRest from "../../service/ApiRest";
import axios from "axios";


interface Props {
    saveData: (options: any) => void,
}

export default function DriversForm(props: Props) {
    const {element, updateElement, isSaving, setIsSaving} = useNaeRest<ITrailer>();
    const {saveData} = props;

    const [filesToUpload, setFilesToUpload] = useState<any>({});
    const addFileToUpload = (key: string, file: File) => {
        const _f = {...filesToUpload, [key]: file}
        setFilesToUpload(_f);
    }

    const fileUploadKeys = Object.keys(filesToUpload);
    const isHaveFiles = fileUploadKeys.length > 0;

    const localSave = () => {
        if (isHaveFiles) {
            setIsSaving(true);
            const fData = new FormData();
            fData.append('folder', 'trailers');
            fileUploadKeys.map(k => {
                // @ts-ignore
                fData.append(k, filesToUpload[k]);
            });

            const def = ApiRest.authRequestOptions('POST');

            return axios.post(
                '/app/files/upload',
                fData,
                {
                    headers: {
                        ...def.headers,
                        'Content-Type': "multipart/form-data",
                    }
                },
            ).then(res => {
                const _files = {...element.files, ...res.data.data};
                saveData({'files': _files});
            });
        } else {
            saveData({});
        }
    }

    return (
        <Card>
            <Card.Header>
                <NaeRouter.BackBtn/>
                {"Puspriekabė"}
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Numeris</Form.Label>
                        <Form.Control value={element.number} onChange={e => updateElement('number', e.target.value)}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Modelis</Form.Label>
                        <Form.Control value={element.mark} onChange={e => updateElement('mark', e.target.value)}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Išmatavimai</Form.Label>
                        <Form.Control value={element.dimensions}
                                      onChange={e => updateElement('dimensions', e.target.value)}/>
                    </Form.Group>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Tinkamumo liudijimas</Form.Label>
                            <DateField value={element.certificateOfSuitabilityValid}
                                       readOnly={fileUploadKeys.indexOf('certificateOfSuitabilityValidFile') === -1}
                                       setValue={val => updateElement('certificateOfSuitabilityValid', val)}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Techninė apžiūra:</Form.Label>
                            <DateField value={element.technicalInspectionValid}
                                       readOnly={fileUploadKeys.indexOf('technicalInspectionValidFile') === -1}
                                       setValue={val => updateElement('technicalInspectionValid', val)}/>
                        </Form.Group>
                    </Form.Row>

                    <Row className={"mb-4"}>
                        <Col sm={"6"}><FileComponent addFileToUpload={addFileToUpload} id={"certificateOfSuitabilityValidFile"}/></Col>
                        <Col sm={"6"}><FileComponent addFileToUpload={addFileToUpload} id={"technicalInspectionValidFile"}/></Col>
                    </Row>

                    <strong>Draudimai</strong>
                    <Form.Row>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>KZ</Form.Label>
                            <DateField value={element.insuranceKzValid}
                                       readOnly={fileUploadKeys.indexOf('insuranceKzValidFile') === -1}
                                       setValue={val => updateElement('insuranceKzValid', val)}/>
                        </Form.Group>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>EU</Form.Label>
                            <DateField value={element.insuranceEuValid}
                                       readOnly={fileUploadKeys.indexOf('insuranceEuValidFile') === -1}
                                       setValue={val => updateElement('insuranceEuValid', val)}/>
                        </Form.Group>

                    </Form.Row>


                    <Row className={"mb-4"}>
                        <Col sm={"6"}><FileComponent addFileToUpload={addFileToUpload} id={"insuranceKzValidFile"}/></Col>
                        <Col sm={"6"}><FileComponent addFileToUpload={addFileToUpload} id={"insuranceEuValidFile"}/></Col>
                    </Row>

                </Form>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col>

                    </Col>
                    <Col className={"text-right"}>
                        <Button type={"button"} variant={isSaving ? "success" : "primary"}
                                onClick={localSave}>{isSaving ? "Saugoma..." : isHaveFiles ? "Įkelti failus ir išsaugoti" : "Išsaugoti"}</Button>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>)
}
