import React, {useEffect, useState} from "react";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {useHistory} from 'react-router-dom'
import ApiRest from "../../service/ApiRest";
import {NaeTable} from "nae-react-core-styles";
import {ITrailer} from "../../types";

const moduleName = 'trailer'

export default function TrailersListPage() {
    const history = useHistory();

    const [readyToLoad, setReadyToLoad] = useState(false);

    const [elements, setElements] = useState<ITrailer[]>([])
    const [dataToRender, setDataToRender] = useState<ITrailer[]>([]);
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');

    const [filter, setFilter] = useState('');

    const getData = () => {
        ApiRest.getElements(moduleName).then(res => {
            setElements(res);
        })
    }

    useEffect(getData, []);

    const filterData = () => {
        if (readyToLoad) {
            let _data = elements;
            if (search) {
                _data = _data.filter((item: ITrailer) => {
                    return item.number.toLowerCase().indexOf(search.toLowerCase()) > -1
                });
            }


            if (filter) {

            }

            setDataToRender(_data);
        }
    }

    useEffect(filterData, [search, activePage, elements, filter, readyToLoad]);

    const restoreHistoryState = () => {
        const params = ['search', 'activePage', 'filter']
        // @ts-ignore
        if (history && history.location && history.location.state) {
            params.map(p => {
                // @ts-ignore
                const v = history.location.state[p];

                if (p === 'search') {
                    setSearch(v);
                } else if (p === 'activePage') {
                    setActivePage(v);
                } else if (p === 'filter') {
                    setFilter(v);
                }
                return p;
            })
        }
        setReadyToLoad(true);
    }
    useEffect(restoreHistoryState, []);

    useEffect(() => {
        if (readyToLoad) {
            setActivePage(1);
        }
    }, [search, filter]);

    const goTo = (item: ITrailer) => {
        history.replace('/trailers', {search, activePage, filter});
        history.push('/' + moduleName + '/' + item.id);
    }

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    return <Container>
        <Row>
            <Col>
                <Card className={"card-table"}>
                    <Card.Header>
                        <Row>
                            <Col className={"v-center"}>
                                Puspriekabės
                            </Col>
                            <Col className={"text-right"}>
                                <Button variant={"success"} onClick={goToNew}>Naujas</Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Header className={"search"}>
                        <Row>
                            <Col className={"v-center"}>
                                <input placeholder={"Paieška"} className={"search-input"} value={search}
                                       onChange={(e) => setSearch(e.target.value)}/>
                            </Col>
                            <Col sm={2}>
                                <select value={filter} onChange={e => setFilter(e.target.value)}>
                                    <option value={""}>Filter</option>
                                </select>
                            </Col>

                        </Row>
                    </Card.Header>
                    <NaeTable.TableWithPaging
                        activePage={activePage}
                        setActivePage={setActivePage}
                        dataToRender={dataToRender} head={
                        <tr>
                            <NaeTable.th id={true}>ID</NaeTable.th>
                            <NaeTable.th>Numeris</NaeTable.th>
                            <NaeTable.th>Modelis</NaeTable.th>
                            <NaeTable.th>Išmatavimai</NaeTable.th>
                            <NaeTable.th>Vilkikas</NaeTable.th>

                        </tr>
                    } renderItem={(item: ITrailer) => {
                        return (
                            <tr onClick={() => goTo(item)} key={"list-row-" + item.id}>
                                <NaeTable.td id={true}>{item.id}</NaeTable.td>
                                <NaeTable.td link={true}>{item.number}</NaeTable.td>
                                <NaeTable.td>{item.mark}</NaeTable.td>
                                <NaeTable.td>{item.dimensions}</NaeTable.td>
                                <NaeTable.td>{item.truckName}</NaeTable.td>
                            </tr>
                        )
                    }}
                    />

                </Card>
            </Col>
        </Row>
    </Container>
}
