import React, {Fragment, useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {NaeRouter} from "nae-react-core-styles";
import DateField from "./Fields/DateField";
import {useNaePopup} from "../NaePopupProvider";
import {useNaeRest} from "../NaeRestProvider";
import {ITyreCheck} from "../../types";
import {NaeRestRemoveBtn} from "nae-react-rest";
import FileComponent from "./File/FileComponent";
import ApiRest from "../../service/ApiRest";
import axios from "axios";

interface Props {
    saveData: (options: any) => void,
}


export default function TyreCheckForm(props: Props) {
    const {isPopup} = useNaePopup();
    const {updateElement, element, moduleName, isSaving, setIsSaving} = useNaeRest<ITyreCheck>();

    const {saveData} = props;

    const [filesToUpload, setFilesToUpload] = useState<any>({});
    const addFileToUpload = (key: string, file: File) => {
        const _f = {...filesToUpload, [key]: file}
        setFilesToUpload(_f);
    }

    const fileUploadKeys = Object.keys(filesToUpload);
    const isHaveFiles = fileUploadKeys.length > 0;

    const localSave = () => {
        if (isHaveFiles) {
            setIsSaving(true);
            const fData = new FormData();
            fData.append('folder', 'tyre-check');
            fileUploadKeys.map(k => {
                // @ts-ignore
                fData.append(k, filesToUpload[k]);
            });

            const def = ApiRest.authRequestOptions('POST');

            return axios.post(
                '/app/files/upload',
                fData,
                {
                    headers: {
                        ...def.headers,
                        'Content-Type': "multipart/form-data",
                    }
                },
            ).then(res => {
                const _files = {...element.files, ...res.data.data};
                saveData({'files': _files});
            });
        } else {
            saveData({});
        }
    }

    const updateTyre = (k: string, val: string, index: number) => {
        const _tyres = JSON.parse(JSON.stringify(element.tyres));
        if (k === 'l') {
            _tyres[index][k] = parseInt(val, 10);
        } else {
            _tyres[index][k] = val;
        }
        updateElement('tyres', _tyres);
    }

    const tyreBlock = (_i: number) => {
        const index = _i - 1;
        const tyre = element.tyres[index];

        return (<Fragment>
            <div className={"tyre tyre-" + index + " " + (_i > 6 ? "tyre-right" : "tyre-left")}>
                <Form.Group>
                    {_i > 6 && <Form.Label>Išmatavimai</Form.Label>}
                    <Form.Control value={tyre.d} onChange={e => updateTyre('d', e.target.value, index)}/>
                    {_i <= 6 && <Form.Label>Išmatavimai</Form.Label>}
                </Form.Group>
                <Form.Group>
                    {_i > 6 && <Form.Label>Pavadinimas</Form.Label>}
                    <Form.Control value={tyre.n} onChange={e => updateTyre('n', e.target.value, index)}/>
                    {_i <= 6 && <Form.Label>Pavadinimas</Form.Label>}
                </Form.Group>
                <Form.Group>
                    {_i > 6 && <Form.Label>Likutis (MM)</Form.Label>}
                    <Form.Control value={tyre.l} onChange={e => updateTyre('l', e.target.value, index)}/>
                    {_i <= 6 && <Form.Label>Likutis (MM)</Form.Label>}
                </Form.Group>
                <span className={"index " + (_i > 6 ? "index-right" : "index-left")}>
                    {_i}
                </span>
            </div>
        </Fragment>)
    }

    return (
        <Card>
            <Card.Header>
                {!isPopup && <NaeRouter.BackBtn/>}
                {"Padangų tikrinimas"}
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Data</Form.Label>
                            <DateField value={element.date} setValue={(v) => updateElement('date', v)}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Kitas patikrinimas</Form.Label>
                            <DateField value={element.nextCheckDate}
                                       setValue={(v) => updateElement('nextCheckDate', v)}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Rida</Form.Label>
                            <Form.Control value={element.mileage}
                                          onChange={e => updateElement('mileage', e.target.value)}/>
                        </Form.Group>
                        <Col sm={"4"} className={"v-center"}><FileComponent addFileToUpload={addFileToUpload} id={"file"}/></Col>
                    </Form.Row>
                </Form>

                {/*{element.tyres.map((t: iTyre, index: number) => {*/}
                {/*    return tyreBlock(index);*/}
                {/*})}*/}
                <Row>
                    <Col>
                        <strong>Vilkikas</strong>
                    </Col>
                </Row>
                <Row className={"mt-4"}>
                    <Col sm={3}>
                        {tyreBlock(12)}
                    </Col>
                    <Col></Col>
                    <Col sm={3}>
                        {tyreBlock(1)}
                    </Col>
                </Row>

                <Row>
                    <Col sm={3}>
                        {tyreBlock(10)}
                    </Col>
                    <Col sm={3}>
                        {tyreBlock(11)}
                    </Col>
                    <Col sm={3}>
                        {tyreBlock(2)}
                    </Col>
                    <Col sm={3}>
                        {tyreBlock(3)}
                    </Col>
                </Row>
                <Row className={"mt-4"}>
                    <Col>
                        <strong>Priekaba</strong>
                    </Col>
                </Row>
                <Row className={"mt-4"}>
                    <Col sm={3}>
                        {tyreBlock(9)}
                    </Col>
                    <Col></Col>
                    <Col sm={3}>
                        {tyreBlock(4)}
                    </Col>
                </Row>
                <Row>
                    <Col sm={3}>
                        {tyreBlock(8)}
                    </Col>
                    <Col></Col>
                    <Col sm={3}>
                        {tyreBlock(5)}
                    </Col>
                </Row>
                <Row>
                    <Col sm={3}>
                        {tyreBlock(7)}
                    </Col>
                    <Col></Col>
                    <Col sm={3}>
                        {tyreBlock(6)}
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col>

                    </Col>
                    <Col className={"text-right"}>
                        {element.id > 0 &&
                        <NaeRestRemoveBtn lang={"lt"} path={'/truck/' + element.truckId} module={moduleName}
                                          id={element.id}/>
                        }
                        <Button className={"ml-2"}  type={"button"} variant={isSaving ? "success" : "primary"}
                                onClick={localSave}>{isSaving ? "Saugoma..." : isHaveFiles ? "Įkelti failus ir išsaugoti" : "Išsaugoti"}</Button>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>)
}
