import React, {useEffect, useState} from "react";
import {Card, Col, Row} from "react-bootstrap";
import {useHistory} from 'react-router-dom'
import ApiRest from "../../../service/ApiRest";
import {NaeTable} from "nae-react-core-styles";
import {IFilterChange} from "../../../types";

const moduleName = 'filter-change'

interface Props {
    options?: any,
    title?: string,
}

export default function FilterChangeListCard(props: Props) {
    const history = useHistory();

    const [readyToLoad, setReadyToLoad] = useState(false);

    const [elements, setElements] = useState<IFilterChange[]>([])
    const [dataToRender, setDataToRender] = useState<IFilterChange[]>([]);
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');

    const [filter, setFilter] = useState('');

    const getData = () => {
        ApiRest.getElements(moduleName, {options: props.options}).then(res => {
            setElements(res);
        })
    }

    useEffect(getData, []);

    const filterData = () => {
        if (readyToLoad) {
            let _data = elements;
            if (search) {
                // _data = _data.filter((item: IFilterChange) => {
                //     return item.fullName.toLowerCase().indexOf(search.toLowerCase()) > -1
                // });
            }


            if (filter) {

            }

            setDataToRender(_data);
        }
    }

    useEffect(filterData, [search, activePage, elements, filter, readyToLoad]);

    const restoreHistoryState = () => {
        const params = ['search', 'activePage', 'filter']
        // @ts-ignore
        if (history && history.location && history.location.state) {
            params.map(p => {
                // @ts-ignore
                const v = history.location.state[p];

                if (p === 'search') {
                    setSearch(v);
                } else if (p === 'activePage') {
                    setActivePage(v);
                } else if (p === 'filter') {
                    setFilter(v);
                }
                return p;
            })
        }
        setReadyToLoad(true);
    }
    useEffect(restoreHistoryState, []);

    useEffect(() => {
        if (readyToLoad) {
            setActivePage(1);
        }
    }, [search, filter]);

    const goTo = (item: IFilterChange) => {
        // history.replace('/drivers', {search, activePage, filter});
        history.push('/' + moduleName + '/' + item.id);
    }

    const goToNew = () => {
        // history.push('/' + moduleName + '/new');
    }

    return (
        <Card className={"card-table"}>
            <Card.Header>
                <Row>
                    <Col className={"v-center"}>
                        Filtrų keitimas
                    </Col>
                    <Col className={"text-right"}>

                    </Col>
                </Row>
            </Card.Header>

            <NaeTable.TableWithPaging
                activePage={activePage}
                setActivePage={setActivePage}
                dataToRender={dataToRender} head={
                <tr>
                    <NaeTable.th id={true}>ID</NaeTable.th>
                    <NaeTable.th>Data</NaeTable.th>
                    <NaeTable.th>Rida</NaeTable.th>

                </tr>
            } renderItem={(item: IFilterChange) => {
                return (
                    <tr onClick={() => goTo(item)} key={"list-row-" + item.id}>
                        <NaeTable.td id={true}>{item.id}</NaeTable.td>
                        <NaeTable.td link={true}>{item.date}</NaeTable.td>
                        <NaeTable.td>{item.mileage}</NaeTable.td>
                    </tr>
                )
            }}
            />

        </Card>
    )
}
