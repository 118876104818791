import React, {useState} from "react";
import {Col, Row} from "react-bootstrap";
import {IDriver} from "../../../types";
import {useNaeRest} from "../../NaeRestProvider";

interface Props {
    id: string,
    addFileToUpload: (key: string, file: File) => void
}


export default function FileComponent(props: Props) {
    const {element} = useNaeRest<IDriver>();
    const {id, addFileToUpload} = props;

    const file = (id in element.files) ? element.files[id] : undefined;

    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const onChange = (e: any) => {
        const file = e.target.files[0]
        setSelectedFile(file);
        addFileToUpload(id, file);
    }

    const downloadFile = (e: any) => {
        e.preventDefault();
        window.open('/app/files-public/download/?f=' + window.btoa(JSON.stringify(file)));
    }

    return (
        <Row>
            {file && <Col sm={1}><a href={"/"} onClick={downloadFile}>{downloadIcon}</a></Col>}
            <Col>
                <label>
                    <input type={"file"} onChange={onChange} style={{display: "none"}}/>
                    <Row>
                        <Col sm={1}>
                            {uploadIcon}
                        </Col>
                        <Col
                            className={"text-sm " + (selectedFile ? "text-success" : (!!file ? "text-info" : "text-danger"))}>
                            {selectedFile ? selectedFile.name : (!!file ? "Įkelti failą" : "Failas neįkeltas")}
                        </Col>
                    </Row>

                </label>
            </Col>
        </Row>
    )
}

const uploadIcon = <svg version="1.1" x="0px" y="0px" viewBox="0 0 490.955 490.955" style={{width: 20}}>
    <path id="XMLID_448_" d="M445.767,308.42l-53.374-76.49v-20.656v-11.366V97.241c0-6.669-2.604-12.94-7.318-17.645L312.787,7.301
	C308.073,2.588,301.796,0,295.149,0H77.597C54.161,0,35.103,19.066,35.103,42.494V425.68c0,23.427,19.059,42.494,42.494,42.494
	h159.307h39.714c1.902,2.54,3.915,5,6.232,7.205c10.033,9.593,23.547,15.576,38.501,15.576c26.935,0-1.247,0,34.363,0
	c14.936,0,28.483-5.982,38.517-15.576c11.693-11.159,17.348-25.825,17.348-40.29v-40.06c16.216-3.418,30.114-13.866,37.91-28.811
	C459.151,347.704,457.731,325.554,445.767,308.42z M170.095,414.872H87.422V53.302h175.681v46.752
	c0,16.655,13.547,30.209,30.209,30.209h46.76v66.377h-0.255v0.039c-17.685-0.415-35.529,7.285-46.934,23.46l-61.586,88.28
	c-11.965,17.134-13.387,39.284-3.722,57.799c7.795,14.945,21.692,25.393,37.91,28.811v19.842h-10.29H170.095z M410.316,345.771
	c-2.03,3.866-5.99,6.271-10.337,6.271h-0.016h-32.575v83.048c0,6.437-5.239,11.662-11.659,11.662h-0.017H321.35h-0.017
	c-6.423,0-11.662-5.225-11.662-11.662v-83.048h-32.574h-0.016c-4.346,0-8.308-2.405-10.336-6.271
	c-2.012-3.866-1.725-8.49,0.783-12.07l61.424-88.064c2.189-3.123,5.769-4.984,9.57-4.984h0.017c3.802,0,7.38,1.861,9.568,4.984
	l61.427,88.064C412.04,337.28,412.328,341.905,410.316,345.771z"/>
</svg>

const downloadIcon = <svg version="1.1" x="0px" y="0px" viewBox="0 0 512 512"
                          style={{width: 20}}>
    <g>
        <g>
            <path d="M382.56,233.376C379.968,227.648,374.272,224,368,224h-64V16c0-8.832-7.168-16-16-16h-64c-8.832,0-16,7.168-16,16v208h-64
			c-6.272,0-11.968,3.68-14.56,9.376c-2.624,5.728-1.6,12.416,2.528,17.152l112,128c3.04,3.488,7.424,5.472,12.032,5.472
			c4.608,0,8.992-2.016,12.032-5.472l112-128C384.192,245.824,385.152,239.104,382.56,233.376z"/>
        </g>
    </g>
    <g>
        <g>
            <path
                d="M432,352v96H80v-96H16v128c0,17.696,14.336,32,32,32h416c17.696,0,32-14.304,32-32V352H432z"/>
        </g>
    </g>
</svg>;
