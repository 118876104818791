import React, {useContext} from "react";

interface ProviderValue {
    isPopup: boolean,
}

export const NaePopupContext = React.createContext<ProviderValue>({
    isPopup: false
});

export const useNaePopup = () => useContext(NaePopupContext);

interface Props {
    children: any;
    isPopup?: boolean
}

export const NaePopupProvider = ({children, isPopup}: Props) => {

    return (
        <NaePopupContext.Provider value={{isPopup: !!isPopup}}>
            {children}
        </NaePopupContext.Provider>
    )
};
