import React, {Fragment, useContext, useEffect, useState} from "react";
import {Redirect, useHistory} from "react-router-dom";
import {NaeLayout, NaeNavBar} from 'nae-react-core-styles'
import {NaeAuthLogoutBtn} from 'nae-react-auth'
import {Nav, Dropdown} from "react-bootstrap";
import {useLocalStorage} from "nae-react-hooks";
import {IUser, userDefElement} from "../../types";
import ApiProfile from "../../service/ApiProfile";


interface ProviderValue {
    user: IUser
}

export const UserContext = React.createContext<ProviderValue>({
    user: userDefElement
})
export const useUser = () => useContext(UserContext);

interface Props {
    children: any,
}

export const UserProvider = (props: Props) => {
    const [loaded, setLoaded] = useState(false);
    const [user, setUser] = useState(userDefElement);
    const [token] = useLocalStorage('token', '');
    const history = useHistory();

    const navigate = (e: any, route: string) => {
        e.preventDefault();
        history.push('/' + route);
    }

    const getData = () => {
        ApiProfile.getProfile().then(res => {
            if (!!res.isError) {
                history.push('/login');
                return;
            }
            setUser(res);
            setLoaded(true);
        }).catch(e => {
            history.push('/login');
        })
    }
    useEffect(getData, [token, history]);

    if (!loaded) {
        return <Fragment/>
    }

    return (
        <UserContext.Provider value={{
            user
        }}>
            {!!user.email ? <NaeLayout.NaePageWrapper top={<NaeNavBar.NaeTopNavbar middleComponent={
                <Nav>
                    <Nav.Link href={'/map'} onClick={(e: any) => navigate(e, 'map')}>Žemėlapis</Nav.Link>

                    <Nav.Link href={'/reports'} onClick={(e: any) => navigate(e, 'reports')}>Ataskaita</Nav.Link>

                    <Nav.Item>
                        <Dropdown>
                            <Dropdown.Toggle variant="link">
                                Duomenys
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="/trucks"
                                               onClick={(e) => navigate(e, 'trucks')}>Vilkikai</Dropdown.Item>
                                <Dropdown.Item href="/trailers"
                                               onClick={(e) => navigate(e, 'trailers')}>Puspriekabės</Dropdown.Item>

                                <Dropdown.Item href="/drivers"
                                               onClick={(e) => navigate(e, 'drivers')}>Vairuotojai</Dropdown.Item>

                                <Dropdown.Item href="/etransport-trucks"
                                               onClick={(e) => navigate(e, 'etransport-trucks')}>Etransportas</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav.Item>

                    {/*<Nav.Link href={'/drivers'} onClick={(e: any) => navigate(e, 'drivers')}>Vairuotojai</Nav.Link>*/}

                    {/*<Nav.Link href={'/trucks'} onClick={(e: any) => navigate(e, 'trucks')}>Vilkikai</Nav.Link>*/}

                    {/*<Nav.Link href={'/trailers'} onClick={(e: any) => navigate(e, 'trailers')}>Puspriekabės</Nav.Link>*/}

                    {/*<Nav.Link href={'/etransport-trucks'} onClick={(e: any) => navigate(e, 'etransport-trucks')}>Etransportas</Nav.Link>*/}



                </Nav>
            } rightComponent={<NaeAuthLogoutBtn lang={"lt"}/>}/>}>
                {props.children}
            </NaeLayout.NaePageWrapper> : <Redirect to={"/login"}/>}
        </UserContext.Provider>

    )
}
