import {NaeRouter} from "nae-react-core-styles";
import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {IDriver, IEtransportasTruck, ITrailer, ITruck} from "../../types";
import DateField from "./Fields/DateField";
import ApiRest from "../../service/ApiRest";

import Select from 'react-select'
import {useNaeRest} from "../NaeRestProvider";
import FileComponent from "./File/FileComponent";
import axios from "axios";

interface Props {
    saveData: (options: any) => void,
}

export default function TruckForm(props: Props) {

    const {element, updateElement, isSaving, setIsSaving} = useNaeRest<ITruck>();

    const {saveData} = props;

    const [drivers, setDrivers] = useState<IDriver[]>([]);
    const [trailers, setTrailers] = useState<ITrailer[]>([]);
    const [etransportasTrucks, setEtransportasTrucks] = useState<IEtransportasTruck[]>([]);

    const getData = () => {
        ApiRest.getElements('driver').then(res => setDrivers(res));
        ApiRest.getElements('trailer').then(res => setTrailers(res));
        ApiRest.getElements('etransport-truck').then(res => setEtransportasTrucks(res));
    }
    useEffect(getData, []);

    const trailerOptions = [{value: 0, label: 'Nepriskirtas'}, ...trailers.map((el: ITrailer) => ({
        value: el.id,
        label: el.number
    }))]
    const driverOptions = [{value: 0, label: 'Nepriskirtas'}, ...drivers.map((el: IDriver) => ({
        value: el.id,
        label: el.fullName
    }))]
    const etransportOptions = [{
        value: 0,
        label: 'Nepriskirtas'
    }, ...etransportasTrucks.map((el: IEtransportasTruck) => ({
        value: el.id,
        label: el.carNr
    }))]

    const [filesToUpload, setFilesToUpload] = useState<any>({});
    const addFileToUpload = (key: string, file: File) => {
        const _f = {...filesToUpload, [key]: file}
        setFilesToUpload(_f);
    }

    const fileUploadKeys = Object.keys(filesToUpload);
    const isHaveFiles = fileUploadKeys.length > 0;

    const localSave = () => {
        if (isHaveFiles) {
            setIsSaving(true);
            const fData = new FormData();
            fData.append('folder', 'trucks');
            fileUploadKeys.map(k => {
                // @ts-ignore
                fData.append(k, filesToUpload[k]);
            });

            const def = ApiRest.authRequestOptions('POST');

            return axios.post(
                '/app/files/upload',
                fData,
                {
                    headers: {
                        ...def.headers,
                        'Content-Type': "multipart/form-data",
                    }
                },
            ).then(res => {
                const _files = {...element.files, ...res.data.data};
                saveData({'files': _files});
            });
        } else {
            saveData({});
        }
    }

    return (
        <Card>
            <Card.Header>
                <NaeRouter.BackBtn/>
                {"Vilkikas"}
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Numeris</Form.Label>
                        <Form.Control value={element.number} onChange={e => updateElement('number', e.target.value)}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Modelis</Form.Label>
                        <Form.Control value={element.mark} onChange={e => updateElement('mark', e.target.value)}/>
                    </Form.Group>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Priekaba</Form.Label>
                            <Select options={trailerOptions}
                                    value={trailerOptions.filter((t) => t.value === element.trailerId)[0]}
                                    onChange={(i) => updateElement('trailerId', i ? i.value : 0)}/>

                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Vairuotojas</Form.Label>
                            <Select options={driverOptions}
                                    value={driverOptions.filter((t) => t.value === element.driverId)[0]}
                                    onChange={(i) => updateElement('driverId', i ? i.value : 0)}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>E-transportas</Form.Label>
                            <Select options={etransportOptions}
                                    value={etransportOptions.filter((t) => t.value === element.etransportTruckId)[0]}
                                    onChange={(i) => updateElement('etransportTruckId', i ? i.value : 0)}/>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Techninė apžiūra:</Form.Label>
                            <DateField value={element.technicalInspectionValid}
                                       readOnly={fileUploadKeys.indexOf('technicalInspectionValidFile') === -1}
                                       setValue={val => updateElement('technicalInspectionValid', val)}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Licenzija:</Form.Label>
                            <DateField value={element.licenceValid}
                                       readOnly={fileUploadKeys.indexOf('licenceValidFile') === -1}
                                       setValue={val => updateElement('licenceValid', val)}/>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Tahografo patikra:</Form.Label>
                            <DateField value={element.tachographCheck}
                                       readOnly={fileUploadKeys.indexOf('tachographCheckFile') === -1}
                                       setValue={val => updateElement('tachographCheck', val)}/>
                        </Form.Group>

                    </Form.Row>
                    <Row className={"mb-4"}>
                        <Col sm={"4"}><FileComponent addFileToUpload={addFileToUpload} id={"technicalInspectionValidFile"}/></Col>
                        <Col sm={"4"}><FileComponent addFileToUpload={addFileToUpload} id={"licenceValidFile"}/></Col>
                        <Col sm={"4"}><FileComponent addFileToUpload={addFileToUpload}
                                                     id={"tachographCheckFile"}/></Col>
                    </Row>


                    <Row className={"mt-2 mb-0"}>
                        <Col><strong>Draudimai</strong></Col>
                    </Row>
                    <Form.Row>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>KZ</Form.Label>
                            <DateField value={element.insuranceKzValid}
                                       readOnly={fileUploadKeys.indexOf('insuranceKzValidFile') === -1}
                                       setValue={val => updateElement('insuranceKzValid', val)}/>
                        </Form.Group>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>EU</Form.Label>
                            <DateField value={element.insuranceEuValid}
                                       readOnly={fileUploadKeys.indexOf('insuranceEuValidFile') === -1}
                                       setValue={val => updateElement('insuranceEuValid', val)}/>
                        </Form.Group>

                    </Form.Row>

                    <Row className={"mb-4"}>
                        <Col sm={"6"}><FileComponent addFileToUpload={addFileToUpload} id={"insuranceKzValidFile"}/></Col>
                        <Col sm={"6"}><FileComponent addFileToUpload={addFileToUpload} id={"insuranceEuValidFile"}/></Col>
                    </Row>

                    <Row className={"mt-2 mb-0"}>
                        <Col><strong>Akumuliatorius</strong></Col>
                    </Row>

                    <Form.Row>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Markė</Form.Label>
                            <Form.Control value={element.batteryMark}
                                          onChange={e => updateElement('batteryMark', e.target.value)}/>
                        </Form.Group>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Pirkimo data</Form.Label>
                            <DateField value={element.batteryPurchaseDate}
                                       setValue={val => updateElement('batteryPurchaseDate', val)}/>
                        </Form.Group>

                    </Form.Row>

                </Form>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col>

                    </Col>
                    <Col className={"text-right"}>
                        <Button type={"button"} variant={isSaving ? "success" : "primary"}
                                onClick={localSave}>{isSaving ? "Saugoma..." : isHaveFiles ? "Įkelti failus ir išsaugoti" : "Išsaugoti"}</Button>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>)
}
