import React from "react";
import {modalCustomStyles} from "../../assets/styles";
import Modal from "react-modal";
import {Container} from "react-bootstrap";
import {NaePopupProvider} from "../NaePopupProvider";

interface Props {
    isOpen: boolean,
    children: React.ReactChild,
    onClose?: () => void,
    className?: string
}

export default function NaeModal(props: Props) {
    return (
        <Modal
            isOpen={props.isOpen}
            onRequestClose={props.onClose}
            style={modalCustomStyles}
            overlayClassName={props.className}
        >
            <NaePopupProvider isPopup={true}>
                <Container>
                    <button onClick={props.onClose} className={"popup-modal-close"}>
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M16.9703 5.70725C17.3612 5.31725 17.3612 4.68325 16.9703 4.29325C16.5802 3.90225 15.9472 3.90225 15.5562 4.29325L10.6322 9.21725L5.70725 4.29325C5.31725 3.90225 4.68325 3.90225 4.29325 4.29325C3.90225 4.68325 3.90225 5.31725 4.29325 5.70725L9.21725 10.6322L4.29325 15.5562C3.90225 15.9472 3.90225 16.5802 4.29325 16.9703C4.68325 17.3612 5.31725 17.3612 5.70725 16.9703L10.6322 12.0463L15.5562 16.9703C15.9472 17.3612 16.5802 17.3612 16.9703 16.9703C17.3612 16.5802 17.3612 15.9472 16.9703 15.5562L12.0463 10.6322L16.9703 5.70725Z"
                                  fill="#475F7B"/>
                        </svg>
                    </button>
                    {props.children}
                </Container>
            </NaePopupProvider>
        </Modal>
    )
}
