import moment from "moment";

export interface IDict {
    [Key: string]: any;
}

export interface IFileDict {
    [Key: string]: IFileItem;
}

export interface IFileItem {
    path: string,
    name: string,
}

export interface IDriver {
    id: number,
    fullName: string,
    passportValid: string,
    // passport2Valid: string,
    // idCardValid: string,
    visaValid: string,
    driverLicenceValid: string,
    driverLicence95Valid: string,

    adrCertificateDate: string,
    code95Date: string,

    // adrCertificate: boolean,
    // code95: boolean,
    phoneWhatsapp: string,
    phoneLt: string,
    phoneBy: string,
    phoneKz: string,
    phoneRu: string,
    phoneKgz: string,
    bank: string,
    bankAccount: string,

    truckId: number,
    truckName: string,

    files: IFileDict
}

const driverDefElement: IDriver = {
    id: 0,

    fullName: '',
    passportValid: '',
    // passport2Valid: '',
    // idCardValid: '',
    visaValid: '',
    driverLicenceValid: '',
    driverLicence95Valid: '',
    // adrCertificate: false,
    // code95: false,
    adrCertificateDate: '',
    code95Date: '',
    phoneWhatsapp: '',
    phoneLt: '',
    phoneBy: '',
    phoneKz: '',
    phoneRu: '',
    phoneKgz: '',
    bank: '',
    bankAccount: '',

    truckId: 0,
    truckName: '',

    files: {}

}

export interface ITruck {
    id: number,
    trailerId: number,
    trailerName: string,
    mark: string,
    technicalInspectionValid: string,
    licenceValid: string,
    tachographCheck: string,
    insuranceKzValid: string,
    insuranceEuValid: string,
    batteryPurchaseDate: string,
    batteryMark: string,
    mileage: number,
    number: string,

    driverId: number,
    driverName: string,

    etransportTruckId: number,
    etransportTruckName: string,
    etransport?: IEtransportasTruck,

    lastPhotoAlbum: string,
    filterChange: number,
    oilChange: number,
    inventory: string,
    tyreChecks: string,

    files: IFileDict,
}

const truckDefElement: ITruck = {
    id: 0,
    trailerId: 0,
    trailerName: '',
    mark: '',
    technicalInspectionValid: '',
    licenceValid: '',
    tachographCheck: '',
    insuranceKzValid: '',
    insuranceEuValid: '',
    batteryPurchaseDate: '',
    batteryMark: '',
    mileage: 0,
    number: '',
    driverId: 0,
    driverName: '',

    etransportTruckId: 0,
    etransportTruckName: '',

    lastPhotoAlbum: '',
    filterChange: 0,
    oilChange: 0,
    inventory: '',
    tyreChecks: '',
    files:{}
}


export interface ITrailer {
    id: number,
    truckId: number,
    truckName: string,
    mark: string,
    dimensions: string,
    certificateOfSuitabilityValid: string,
    technicalInspectionValid: string,
    insuranceKzValid: string,
    insuranceEuValid: string,
    number: string,

    files: IFileDict,
}

const trailerDefElement: ITrailer = {
    id: 0,
    truckId: 0,
    truckName: '',
    mark: '',
    dimensions: '',
    certificateOfSuitabilityValid: '',
    technicalInspectionValid: '',
    insuranceKzValid: '',
    insuranceEuValid: '',
    number: '',
    files: {},
}

export interface IUser {
    id: number,
    email: string,
}

const userDefElement: IUser = {
    id: 0,
    email: '',
}

export interface IEtransportasTruck {
    id: number,
    carNr: string,
    lon: number,
    lat: number,
    locality: string,
    timestamp: string,
    odo: string,
    fuel: number,
    status: string,
    missSignal: boolean
}

export interface IInventory {
    id: number,
    date: string,
    truckId: number,
    truckName: string,
    mileage: number,

    files: IFileDict
}

const inventoryDefElement: IInventory = {
    id: 0,
    date: moment().format('YYYY-MM-DD'),
    truckId: 0,
    truckName: '',
    mileage: 0,
    files: {}
}

export interface IFilterChange {
    id: number,
    date: string,
    truckId: number,
    truckName: string,
    mileage: number,
    validMileage: number,

    files: IFileDict
}

const filterChangeDefElement: IFilterChange = {
    id: 0,
    date: moment().format('YYYY-MM-DD'),
    truckId: 0,
    truckName: '',
    mileage: 0,
    validMileage: 50000,
    files: {}
}

export interface IOilChange {
    id: number,
    date: string,
    truckId: number,
    truckName: string,
    mileage: number,
    validMileage: number,

    files: IFileDict
}

const oilChangeDefElement: IOilChange = {
    id: 0,
    date: moment().format('YYYY-MM-DD'),
    truckId: 0,
    truckName: '',
    mileage: 0,
    validMileage: 50000,
    files: {}
}

export interface IPhotoAlbum {
    id: number,
    date: string,
    truckId: number,
    truckName: string,

    files: IFileDict
}

const photoAlbumDefElement: IPhotoAlbum = {
    id: 0,
    date: moment().format('YYYY-MM-DD'),
    truckId: 0,
    truckName: '',
    files: {},
}

export interface ITyreCheck {
    id: number,
    date: string,
    truckId: number,
    truckName: string,
    mileage: number,
    nextCheckDate: string,
    tyres: iTyre[],
    minTyre: number,

    files: IFileDict
}

export interface iTyre {
    d: string,
    n: string,
    l: number
}

const defTyres = [];
for (let i = 0; i < 12; i++) {
    defTyres.push({
        d: '',
        n: '',
        l: 0
    })
}

const tyreCheckDefElement: ITyreCheck = {
    id: 0,
    date: moment().format('YYYY-MM-DD'),
    truckId: 0,
    truckName: '',
    mileage: 0,
    nextCheckDate: moment().add(3, 'M').format('YYYY-MM-DD'),
    tyres: defTyres,
    minTyre: 0,
    files: {}
}

export {
    userDefElement,
    driverDefElement,
    trailerDefElement,
    truckDefElement,
    inventoryDefElement,
    oilChangeDefElement,
    filterChangeDefElement,
    tyreCheckDefElement,
    photoAlbumDefElement,
}
