import React, {Fragment} from "react";
import {useHistory} from 'react-router-dom'
import {NaeLayout} from 'nae-react-core-styles'
import {Card} from "react-bootstrap";
import {NaeRestRemoveBtn} from "nae-react-rest";
import TrailersForm from "../Forms/TrailersForm";
import {useNaeRest} from "../NaeRestProvider";

const moduleName = 'trailer';

const keysToSave = [
    'number',
    'mark',
    'dimensions',
    'certificateOfSuitabilityValid',
    'technicalInspectionValid',
    'insuranceKzValid',
    'insuranceEuValid',
    'files'
]

export default function TrailerPage() {
    const {element, id, restDoSaveWithKeys} = useNaeRest();

    const form = (element.id > 0 || id === 'new') ?
        <Fragment>
            <TrailersForm saveData={(extra?: any) => restDoSaveWithKeys(keysToSave, extra)}/>
        </Fragment>
        : <Fragment/>
    ;

    const rightComponent = (
        element.id > 0 ?
            <Card>
                <Card.Body>
                    <NaeRestRemoveBtn lang={"lt"} path={'/trailers'} module={moduleName} id={element.id}/>
                </Card.Body>
            </Card>
            :
            <Fragment/>
    );

    return (<Fragment>

            <NaeLayout.NaePageViewLayout leftComponent={form} rightComponent={rightComponent}/>

        </Fragment>
    )
}
