import React, {Fragment} from "react";
import {Col, Row} from "react-bootstrap";
import {useNaeRest} from "../../NaeRestProvider";
import TyreCheckForm from "../../Forms/TyreCheckForm";

interface Props {
    truckId?: number
}

const keys = [
    'truckId',
    'date',
    'mileage',
    'nextCheckDate',
    'tyres',
    'files'
]

export default function TyreCheckPage(props: Props) {
    const {id, restDoSaveWithKeys, element} = useNaeRest();
    const {truckId} = props;

    const form = (element.id > 0 || id === 'new') ?
        <Fragment>
            <TyreCheckForm saveData={(options) => restDoSaveWithKeys(keys, {truckId, ...options})}/>
        </Fragment>
        : <Fragment/>
    ;


    return (<Fragment>

            <Row>
                <Col>
                    {form}
                </Col>
            </Row>


        </Fragment>
    )
}
