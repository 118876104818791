import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import InventoriesListCard from "./InventoriesListCard";

export default function InventoriesListPage() {

    return <Container>
        <Row>
            <Col>
                <InventoriesListCard/>
            </Col>
        </Row>
    </Container>
}

