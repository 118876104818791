import React, {Fragment} from "react";
import {NaeLayout} from 'nae-react-core-styles'
import {Card, Col, Row} from "react-bootstrap";
import {NaeRestRemoveBtn} from "nae-react-rest";
import DriversForm from "../Forms/DriversForm";
import {useNaeRest} from "../NaeRestProvider";

const moduleName = 'driver';

const keysToSave = [
    'fullName',
    'passportValid',
    'visaValid',
    'driverLicenceValid',
    'driverLicence95Valid',
    'adrCertificateDate',
    'code95Date',
    'phoneWhatsapp',
    'phoneLt',
    'phoneBy',
    'phoneRu',
    'phoneKz',
    'phoneKgz',
    'bank',
    'bankAccount',
    'files'
];

export default function DriverPage() {
    const {element, id, restDoSaveWithKeys} = useNaeRest();

    const form = (element.id > 0 || id === 'new') ?
        <Fragment>
            <DriversForm saveData={(extra?: any) => restDoSaveWithKeys(keysToSave, extra)}/>
        </Fragment>
        : <Fragment/>
    ;

    const rightComponent = (
        element.id > 0 ?
            <Fragment>
                <Card>
                    <Card.Body>
                        <NaeRestRemoveBtn lang={"lt"} path={'/drivers'} module={moduleName} id={element.id}/>
                    </Card.Body>
                </Card>

            </Fragment>
            :
            <Fragment/>
    );

    return (<Fragment>

            <NaeLayout.NaePageViewLayout leftComponent={form} rightComponent={rightComponent}/>

        </Fragment>
    )
}
