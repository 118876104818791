import React, {Fragment, useEffect, useState} from "react";
import {Col, Row, Table} from 'react-bootstrap'
import {IDriver, ITrailer, ITruck} from "../../types";
import ApiRest from "../../service/ApiRest";
import moment from "moment";

import tyreIcon from '../../assets/icons/tyre.png';
import filterIcon from '../../assets/icons/filter.png';
import oilIcon from '../../assets/icons/oil.png';
import photoIcon from '../../assets/icons/photo.png';
import inventoryIcon from '../../assets/icons/inventory.png';
import {useHistory} from "react-router-dom";


const moduleName = 'truck'

export default function ReportPage() {
    const history = useHistory();

    const [trucks, setTrucks] = useState<ITruck[]>([])
    const [trailers, setTrailers] = useState<ITrailer[]>([])
    const [drivers, setDrivers] = useState<IDriver[]>([])

    const getData = () => {
        ApiRest.getElements('truck').then(res => {
            setTrucks(res);
        })
        ApiRest.getElements('trailer').then(res => {
            setTrailers(res);
        })
        ApiRest.getElements('driver').then(res => {
            setDrivers(res);
        })
    }

    useEffect(getData, []);

    const after2M = moment().add(2, 'M');
    const after1M = moment().add(1, 'M');

    const before2M = moment().subtract(2, 'M');
    const before3M = moment().subtract(3, 'M');

    const checkValid = (d1: any, d2: any, d3?: any) => {
        // console.log('d1', d1.format("YYYY-MM-DD"), d2.format("YYYY-MM-DD"), d3.format("YYYY-MM-DD"))
        if (d1 > d2) {
            return 'success';
        }
        if (!!d3 && d1 > d3) {
            return 'warning';
        }
        return 'error';
    }

    const goTo = (item: ITruck) => {
        history.push('/' + moduleName + '/' + item.id);
    }

    return (
        <Fragment>
            <Row>
                <Col>
                    <Table className={"reports"}>
                        <thead>
                        <th></th>
                        <th></th>
                        <th colSpan={10}>Vilkikas</th>
                        <th colSpan={4}>Priekaba</th>
                        <th colSpan={5}>Vairuotojas</th>
                        </thead>
                        <thead>
                        <th></th>
                        <th></th>
                        <th title={"TECHNINĖ APŽIŪRA"}>TA</th>
                        <th title={"LICENZIJA"}>L</th>
                        <th title={"TAHOGRAFO PATIKRA"}>TP</th>
                        <th title={"DRAUDIMAS KZ"}>DKZ</th>
                        <th title={"DRAUDIMAS EU"}>DEU</th>

                        <th title={"Padangų tikrinimas"}><img src={tyreIcon} alt={""}/></th>
                        <th title={"Filtrų keitimas"}><img src={filterIcon} alt={""}/></th>
                        <th title={"Tepalų keitimas"}><img src={oilIcon} alt={""}/></th>
                        <th title={"Fotografavimas"}><img src={photoIcon} alt={""}/></th>
                        <th title={"Inventorizacijos"}><img src={inventoryIcon} alt={""}/></th>

                        <th title={"TINKAMUMO LIUDIJIMAS"}>c</th>
                        <th title={"TECHNINĖ APŽIŪRA"}>TA</th>
                        <th title={"DRAUDIMAS KZ"}>DKZ</th>
                        <th title={"DRAUDIMAS EU"}>DEU</th>

                        <th title={"VAIRUOTOJO PASAS"}>P</th>
                        <th title={"VAIRUOTOJO VIZA"}>V</th>
                        <th title={"VAIRUOTOJO PAŽYMĖJIMAS"}>T</th>
                        <th title={"ADR PAŽYMĖJIMAS"}>A</th>
                        <th title={"95 KODAS"}>K</th>
                        <th title={"VAIRUOTOJO LIUDIJIMAS"}>L</th>

                        </thead>
                        <tbody>
                        {trucks.map((truck: ITruck) => {
                            const _trailers = trailers.filter((t: ITrailer) => t.id === truck.trailerId);
                            const trailer = _trailers.length > 0 ? _trailers[0] : null;

                            const _drivers = drivers.filter((t: IDriver) => t.id === truck.driverId);
                            const driver = _drivers.length > 0 ? _drivers[0] : null;

                            return (
                                <tr>
                                    <td onClick={() => goTo(truck)}
                                        className={"link"}>{truck.number} ({truck.trailerName})<br/>
                                        {truck.driverName}</td>
                                    <td>{truck.mileage}</td>
                                    <td title={truck.technicalInspectionValid + " TECHNINĖ APŽIŪRA"}
                                        className={checkValid(moment(truck.technicalInspectionValid), after2M, after1M)}/>
                                    <td title={truck.licenceValid + " LICENZIJA"}
                                        className={checkValid(moment(truck.licenceValid), after2M, after1M)}
                                    />
                                    <td title={truck.tachographCheck + " TAHOGRAFO PATIKRA"}
                                        className={checkValid(moment(truck.tachographCheck), after2M, after1M)}/>
                                    <td title={truck.insuranceKzValid + " DRAUDIMAS KZ"}
                                        className={checkValid(moment(truck.insuranceKzValid), after2M, after1M)}/>
                                    <td title={truck.insuranceEuValid + " DRAUDIMAS EU"}
                                        className={checkValid(moment(truck.insuranceEuValid), after2M, after1M)}/>

                                    <td title={truck.tyreChecks + " Padangų tikrinimas"}
                                        className={checkValid(moment(truck.tyreChecks), after2M, after1M)}/>
                                    <td title={truck.filterChange.toString() + " Filtrų keitimas"}
                                        className={truck.mileage < truck.filterChange ? "success" : "error"}
                                    />
                                    <td title={truck.oilChange.toString() + " Tepalų keitimas"}
                                        className={truck.mileage < truck.oilChange ? "success" : "error"}/>

                                    <td title={truck.lastPhotoAlbum + " Fotografavimas"}
                                        className={checkValid(moment(truck.lastPhotoAlbum), before3M, before2M)}/>

                                    <td title={truck.inventory + " Inventorizacijos"}
                                        className={checkValid(moment(truck.inventory), before3M, before2M)}/>


                                    {trailer ?
                                        <Fragment>
                                            <td title={trailer.certificateOfSuitabilityValid + " TINKAMUMO LIUDIJIMAS"}
                                                className={checkValid(moment(trailer.certificateOfSuitabilityValid), after2M, after1M)}/>
                                            <td title={trailer.technicalInspectionValid + " TECHNINĖ APŽIŪRA"}
                                                className={checkValid(moment(trailer.technicalInspectionValid), after2M, after1M)}/>
                                            <td title={trailer.insuranceKzValid + " DRAUDIMAS KZ"}
                                                className={checkValid(moment(trailer.insuranceKzValid), after2M, after1M)}/>
                                            <td title={trailer.insuranceEuValid + " DRAUDIMAS EU"}
                                                className={checkValid(moment(trailer.insuranceEuValid), after2M, after1M)}/>
                                        </Fragment>
                                        :
                                        <td colSpan={4} className={"inactive"}>-</td>}

                                    {driver ?
                                        <Fragment>
                                            <td title={driver.passportValid + " VAIRUOTOJO PASAS"}
                                                className={checkValid(moment(driver.passportValid), after2M, after1M)}/>
                                            {/*<td title={driver.passport2Valid+" VAIRUOTOJO PASAS 2"}*/}
                                            {/*    className={checkValid(moment(driver.passport2Valid), after2M, after1M)}/>*/}
                                            {/*<td title={driver.idCardValid+" ID KORTELĖ"}*/}
                                            {/*    className={checkValid(moment(driver.idCardValid), after2M, after1M)}/>*/}
                                            <td title={driver.visaValid + " VAIRUOTOJO VIZA"}
                                                className={checkValid(moment(driver.visaValid), after2M, after1M)}/>
                                            <td title={driver.driverLicenceValid + " VAIRUOTOJO PAŽYMĖJIMAS"}
                                                className={checkValid(moment(driver.driverLicenceValid), after2M, after1M)}/>
                                            <td title={driver.adrCertificateDate + " ADR PAŽYMĖJIMAS"}
                                                className={checkValid(moment(driver.adrCertificateDate), after2M, after1M)}/>
                                            <td title={driver.code95Date + " 95 KODAS"}
                                                className={checkValid(moment(driver.code95Date), after2M, after1M)}/>
                                            <td title={driver.driverLicence95Valid + " VAIRUOTOJO LIUDIJIMAS"}
                                                className={checkValid(moment(driver.driverLicence95Valid), after2M, after1M)}/>
                                        </Fragment>
                                        :
                                        <td colSpan={5} className={"inactive"}>-</td>}
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Fragment>
    )
}
