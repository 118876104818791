import {NaeRouter} from "nae-react-core-styles";
import React, {useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {IDriver} from "../../types";
import DateField from "./Fields/DateField";
import {useNaeRest} from "../NaeRestProvider";
import FileComponent from "./File/FileComponent";
import ApiRest from "../../service/ApiRest";
import axios from "axios";

interface Props {
    saveData: (options: any) => void,
}

export default function DriversForm(props: Props) {
    const {element, updateElement, isSaving, setIsSaving} = useNaeRest<IDriver>();
    const {saveData} = props;

    const [filesToUpload, setFilesToUpload] = useState<any>({});
    const addFileToUpload = (key: string, file: File) => {
        const _f = {...filesToUpload, [key]: file}
        setFilesToUpload(_f);
    }

    const fileUploadKeys = Object.keys(filesToUpload);
    const isHaveFiles = fileUploadKeys.length > 0;

    const localSave = () => {
        if (isHaveFiles) {
            setIsSaving(true);
            const fData = new FormData();
            fData.append('folder', 'drivers');
            fileUploadKeys.map(k => {
                // @ts-ignore
                fData.append(k, filesToUpload[k]);
            });

            const def = ApiRest.authRequestOptions('POST');

            return axios.post(
                '/app/files/upload',
                fData,
                {
                    headers: {
                        ...def.headers,
                        'Content-Type': "multipart/form-data",
                    }
                },
            ).then(res => {
                const _files = {...element.files, ...res.data.data};
                saveData({'files': _files});
            });
        } else {
            saveData({});
        }
    }

    return (
        <Card>
            <Card.Header>
                <NaeRouter.BackBtn/>
                {"Vairuotojas"}
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Vardas, pavardė</Form.Label>
                        <Form.Control value={element.fullName}
                                      onChange={e => updateElement('fullName', e.target.value)}/>
                    </Form.Group>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Vairuotojo pasas</Form.Label>
                            <DateField value={element.passportValid}
                                       readOnly={fileUploadKeys.indexOf('passportValidFile') === -1}
                                       setValue={value => updateElement('passportValid', value)}/>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Vairuotojo viza</Form.Label>
                            <DateField value={element.visaValid} setValue={value => updateElement('visaValid', value)}

                                       readOnly={fileUploadKeys.indexOf('visaValidFile') === -1}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Vairuotojo pažymėjimas</Form.Label>
                            <DateField value={element.driverLicenceValid}
                                       readOnly={fileUploadKeys.indexOf('driverLicenceValidFile') === -1}
                                       setValue={value => updateElement('driverLicenceValid', value)}/>
                        </Form.Group>

                    </Form.Row>
                    <Row className={"mb-4"}>
                        <Col sm={"4"}><FileComponent addFileToUpload={addFileToUpload} id={"passportValidFile"}/></Col>
                        <Col sm={"4"}><FileComponent addFileToUpload={addFileToUpload} id={"visaValidFile"}/></Col>
                        <Col sm={"4"}><FileComponent addFileToUpload={addFileToUpload}
                                                     id={"driverLicenceValidFile"}/></Col>
                    </Row>
                    <Form.Row>

                        <Form.Group as={Col}>
                            <Form.Label>ADR pažymėjimas</Form.Label>
                            <DateField value={element.adrCertificateDate}
                                       readOnly={fileUploadKeys.indexOf('adrCertificateDateFile') === -1}
                                       setValue={value => updateElement('adrCertificateDate', value)}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>95 kodas</Form.Label>
                            <DateField value={element.code95Date}
                                       readOnly={fileUploadKeys.indexOf('code95DateFile') === -1}
                                       setValue={value => updateElement('code95Date', value)}/>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Vairuotojo liudijimas</Form.Label>
                            <DateField value={element.driverLicence95Valid}
                                       readOnly={fileUploadKeys.indexOf('driverLicence95ValidFile') === -1}
                                       setValue={value => updateElement('driverLicence95Valid', value)}/>
                        </Form.Group>
                    </Form.Row>
                    <Row className={"mb-4"}>
                        <Col sm={"4"}><FileComponent addFileToUpload={addFileToUpload}
                                                     id={"adrCertificateDateFile"}/></Col>
                        <Col sm={"4"}><FileComponent addFileToUpload={addFileToUpload} id={"code95DateFile"}/></Col>
                        <Col sm={"4"}><FileComponent addFileToUpload={addFileToUpload} id={"driverLicence95ValidFile"}/></Col>
                    </Row>

                    <strong>Telefonai</strong>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>WhatsApp/Viber</Form.Label>
                            <Form.Control value={element.phoneWhatsapp}
                                          onChange={e => updateElement('phoneWhatsapp', e.target.value)}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>LT</Form.Label>
                            <Form.Control value={element.phoneLt}
                                          onChange={e => updateElement('phoneLt', e.target.value)}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>BY</Form.Label>
                            <Form.Control value={element.phoneBy}
                                          onChange={e => updateElement('phoneBy', e.target.value)}/>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>

                        <Form.Group as={Col} className={"mt-0"}>
                            <Form.Label>RU</Form.Label>
                            <Form.Control value={element.phoneRu}
                                          onChange={e => updateElement('phoneRu', e.target.value)}/>
                        </Form.Group>
                        <Form.Group as={Col} className={"mt-0"}>
                            <Form.Label>KZ</Form.Label>
                            <Form.Control value={element.phoneKz}
                                          onChange={e => updateElement('phoneKz', e.target.value)}/>
                        </Form.Group>
                        <Form.Group as={Col} className={"mt-0"}>
                            <Form.Label>KGZ</Form.Label>
                            <Form.Control value={element.phoneKgz}
                                          onChange={e => updateElement('phoneKgz', e.target.value)}/>
                        </Form.Group>
                    </Form.Row>

                    <strong>Sąskaita</strong>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Bankas</Form.Label>
                            <Form.Control value={element.bank} onChange={e => updateElement('bank', e.target.value)}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Banko sąskaita</Form.Label>
                            <Form.Control value={element.bankAccount}
                                          onChange={e => updateElement('bankAccount', e.target.value)}/>
                        </Form.Group>
                    </Form.Row>
                </Form>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col>

                    </Col>
                    <Col className={"text-right"}>
                        <Button type={"button"} variant={isSaving ? "success" : "primary"}
                                onClick={localSave}>{isSaving ? "Saugoma..." : isHaveFiles ? "Įkelti failus ir išsaugoti" : "Išsaugoti"}</Button>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>)
}
