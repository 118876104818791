import React, {useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {NaeRouter} from "nae-react-core-styles";
import DateField from "./Fields/DateField";
import {useNaePopup} from "../NaePopupProvider";
import {useNaeRest} from "../NaeRestProvider";
import {IFilterChange} from "../../types";
import {NaeRestRemoveBtn} from "nae-react-rest";
import FileComponent from "./File/FileComponent";
import ApiRest from "../../service/ApiRest";
import axios from "axios";

interface Props {
    saveData: (options: any) => void,
}


export default function FilterChangeForm(props: Props) {
    const {isPopup} = useNaePopup();
    const {updateElement, element, moduleName, isSaving, setIsSaving} = useNaeRest<IFilterChange>();

    const [filesToUpload, setFilesToUpload] = useState<any>({});
    const addFileToUpload = (key: string, file: File) => {
        const _f = {...filesToUpload, [key]: file}
        setFilesToUpload(_f);
    }

    const fileUploadKeys = Object.keys(filesToUpload);
    const isHaveFiles = fileUploadKeys.length > 0;

    const {saveData} = props;

    const localSave = () => {
        if (isHaveFiles) {
            setIsSaving(true);
            const fData = new FormData();
            fData.append('folder', 'filter-changes');
            fileUploadKeys.map(k => {
                // @ts-ignore
                fData.append(k, filesToUpload[k]);
            });

            const def = ApiRest.authRequestOptions('POST');

            return axios.post(
                '/app/files/upload',
                fData,
                {
                    headers: {
                        ...def.headers,
                        'Content-Type': "multipart/form-data",
                    }
                },
            ).then(res => {
                const _files = {...element.files, ...res.data.data};
                saveData({'files': _files});
            });
        } else {
            saveData({});
        }
    }

    return (
        <Card>
            <Card.Header>
                {!isPopup && <NaeRouter.BackBtn/>}
                {"Filtrų keitimas"}
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Data</Form.Label>
                        <DateField value={element.date} setValue={(v) => updateElement('date', v)}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Rida</Form.Label>
                        <Form.Control value={element.mileage} onChange={e => updateElement('mileage', e.target.value)}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Leidžiamas kilometražas</Form.Label>
                        <Form.Control value={element.validMileage}
                                      onChange={e => updateElement('validMileage', e.target.value)}/>
                    </Form.Group>
                    <Row>
                        <Col sm={"12"}><FileComponent addFileToUpload={addFileToUpload} id={"file"}/></Col>
                    </Row>
                </Form>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col>

                    </Col>
                    <Col className={"text-right"}>
                        {element.id > 0 &&
                        <NaeRestRemoveBtn lang={"lt"} path={'/truck/' + element.truckId} module={moduleName}
                                          id={element.id}/>
                        }

                        <Button className={"ml-2"} type={"button"} variant={isSaving ? "success" : "primary"}
                                onClick={localSave}>{isSaving ? "Saugoma..." : isHaveFiles ? "Įkelti failus ir išsaugoti" : "Išsaugoti"}</Button>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>)
}
