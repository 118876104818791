import React, {Fragment} from 'react';
import 'nae-react-core-styles/dist/index.css';
import {NaeApiAuth, NaeAuthLoginPage, NaeAuthPasswordRemindPage, NaeAuthRegisterPage} from 'nae-react-auth'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {UserProvider} from "./Components/User/UserProvider";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import DriversListPage from "./Components/Pages/DriversListPage";
import DriverPage from "./Components/Pages/DriverPage";
import TrailersListPage from "./Components/Pages/TrailersListPage";
import TrailerPage from "./Components/Pages/TrailerPage";
import TrucksListPage from "./Components/Pages/TrucksListPage";
import TruckPage from "./Components/Pages/TruckPage";
import EtransportasTrucksListPage from "./Components/Pages/EtransportasTrucksListPage";
import MapsPage from "./Components/Pages/Map/MapsPage";
import InventoriesListPage from "./Components/Pages/Inventory/InventoriesListPage";
import {NaeRestProvider} from "./Components/NaeRestProvider";
import {
    driverDefElement,
    filterChangeDefElement,
    inventoryDefElement,
    oilChangeDefElement,
    photoAlbumDefElement,
    trailerDefElement,
    truckDefElement,
    tyreCheckDefElement
} from "./types";
import TyreCheckPage from "./Components/Pages/TyreCheck/TyreCheckPage";
import ReportPage from "./Components/Pages/ReportPage";
import OilChangePage from "./Components/Pages/OilChange/OilChangePage";
import FilterChangePage from "./Components/Pages/FilterChange/FilterChangePage";
import InventoryPage from "./Components/Pages/Inventory/InventoryPage";
import PhotoAlbumPage from "./Components/Pages/PhotoAlbum/PhotoAlbumPage";

// @ts-ignore
NaeApiAuth.baseUrl = '/app/auth';

function App() {
    return (
        <Fragment>
            <Router>
                <Switch>
                    <Route path={"/login"}><NaeAuthLoginPage lang={"lt"}/></Route>
                    <Route path={"/register"}><NaeAuthRegisterPage lang={"lt"}/></Route>
                    <Route path={"/password-remind"}><NaeAuthPasswordRemindPage lang={"lt"}/></Route>

                    <UserProvider>
                        <Fragment>
                            <Route path={"/reports"}><ReportPage/></Route>

                            <Route path={"/trucks"}><TrucksListPage/></Route>
                            <Route path={"/truck/:id"}>
                                <NaeRestProvider moduleName={'truck'} defElement={truckDefElement}>
                                    <TruckPage/>
                                </NaeRestProvider>
                            </Route>

                            <Route path={"/tyre-check/:id"}>
                                <NaeRestProvider moduleName={'tyre-check'} defElement={tyreCheckDefElement}>
                                    <TyreCheckPage/>
                                </NaeRestProvider>
                            </Route>

                            <Route path={"/oil-change/:id"}>
                                <NaeRestProvider moduleName={'oil-change'} defElement={oilChangeDefElement}>
                                    <OilChangePage/>
                                </NaeRestProvider>
                            </Route>

                            <Route path={"/filter-change/:id"}>
                                <NaeRestProvider moduleName={'filter-change'} defElement={filterChangeDefElement}>
                                    <FilterChangePage/>
                                </NaeRestProvider>
                            </Route>

                            <Route path={"/inventory/:id"}>
                                <NaeRestProvider moduleName={'inventory'} defElement={inventoryDefElement}>
                                    <InventoryPage/>
                                </NaeRestProvider>
                            </Route>

                            <Route path={"/photo-album/:id"}>
                                <NaeRestProvider moduleName={'photo-album'} defElement={photoAlbumDefElement}>
                                    <PhotoAlbumPage/>
                                </NaeRestProvider>
                            </Route>

                            <Route path={"/inventories"}><InventoriesListPage/></Route>

                            <Route path={"/drivers"}><DriversListPage/></Route>
                            <Route path={"/driver/:id"}>
                                <NaeRestProvider moduleName={'driver'} defElement={driverDefElement}>
                                    <DriverPage/>
                                </NaeRestProvider>
                            </Route>

                            <Route path={"/trailers"}><TrailersListPage/></Route>
                            <Route path={"/trailer/:id"}>
                                <NaeRestProvider moduleName={'trailer'} defElement={trailerDefElement}>
                                    <TrailerPage/>
                                </NaeRestProvider>
                            </Route>

                            <Route path={"/etransport-trucks"}><EtransportasTrucksListPage/></Route>

                            <Route path={"/map"}><MapsPage/></Route>
                        </Fragment>
                    </UserProvider>
                </Switch>
            </Router>
            <ToastContainer/>
        </Fragment>
    );
}

export default App;
