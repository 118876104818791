import React, {Fragment, useEffect} from "react";
import {Marker, Popup, useMap} from "react-leaflet";
import {ITruck} from "../../../types";
import L from "leaflet";
// @ts-ignore
import MarkerClusterGroup from 'react-leaflet-markercluster';

interface Props {
    elements: ITruck[],
    zoomToMarker: number,
}

const markerHeight = 20;
const markerWidth = 100;

const createClusterCustomIcon = function (cluster: any) {

    const markers = cluster.getAllChildMarkers();
    const text = markers.map((m: any) => {
        const truck: ITruck = JSON.parse(m.options.title);
        const classes = ['map-marker', truck.etransport?.status];
        if (truck.etransport?.missSignal) {
            classes.push('miss-signal');
        }

        return `<div class="${classes.join(" ")}">${truck.number}</div>`;
    })
    const h = markerHeight * markers.length;
    return L.divIcon({
        html: `<div>
                <div>
                    ${text.join(" ")}
                </div>
            </div>`,
        className: 'marker-cluster-custom',
        iconSize: [markerWidth, h],
    });
}


export default function MapsMarkers(props: Props) {
    const {elements} = props;
    const map = useMap();

    useEffect(() => {
        if (elements.length > 0) {
            const bounds: any = elements.filter((truck: ITruck) => !!truck.etransport).map((truck: ITruck) => [truck.etransport ? truck.etransport.lat : 0, truck.etransport ? truck.etransport.lon : 0])
            map.fitBounds(bounds, {padding: [100, 100]});
        }
    }, [elements]);

    const zoomToMarker =() => {
        console.log('zoomToMarker', props.zoomToMarker);
        if (props.zoomToMarker > 0) {
            const filter = elements.filter((truck: ITruck) => truck.id === props.zoomToMarker);
            if (filter.length > 0) {
                const truck = filter[0];
                if (!!truck.etransport) {
                    map.flyTo([truck.etransport.lat, truck.etransport.lon], 10);
                }
            }
        }
    }
    useEffect(zoomToMarker, [props.zoomToMarker]);

    return (
        <Fragment>
            <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
                {elements.filter((truck: ITruck) => !!truck.etransport).map((truck: ITruck, index: number) => {

                    const classes = ['map-marker', truck.etransport?.status];
                    if (truck.etransport?.missSignal) {
                        classes.push('miss-signal');
                    }

                    const text = L.divIcon({
                        iconSize: [markerWidth, markerHeight],
                        // iconAnchor: [150, 20],
                        html: truck.number,
                        className: classes.join(" "),
                    });

                    if (!truck.etransport) {
                        return <Fragment/>
                    }

                    return (
                        <Marker key={"marker-"+truck.id} title={JSON.stringify(truck)} icon={text}
                                position={[truck.etransport.lat, truck.etransport.lon]}>
                            <Popup>
                                <strong>{truck.number}/{truck.trailerName}</strong>
                                <br/>{truck.driverName}
                                <br/> {truck.etransport?.locality}
                                <br/>{truck.etransport?.timestamp}
                            </Popup>
                        </Marker>
                    )
                })}
            </MarkerClusterGroup>
        </Fragment>
    )
}
