import React, {useContext, useEffect, useState} from "react";
import ApiRest from "../service/ApiRest";
import {toast} from "react-toastify";
import {useNaePopup} from "./NaePopupProvider";
import {useHistory, useParams} from "react-router-dom";
import {IDict} from "../types"
import {once} from "lodash";

interface ProviderValue<T> {
    restDoSave: (options: any) => void,
    restDoSaveWithKeys: (keys: string[], extra?: any) => void,
    id: string,
    element: T,
    updateElement: (key: string, val: any) => void,
    moduleName: string,
    isSaving: boolean,
    setIsSaving: (val: boolean) => void,
}

const createStateContext = once(<T, >() => React.createContext({} as ProviderValue<T>));

export const useNaeRest = <T extends IDict>() => useContext<ProviderValue<T>>(createStateContext<T>());

interface Props<T> {
    children: any;
    moduleName: string,
    defElement: T,

    id?: string,
    onSave?: (res: any, id: string) => void,

}

interface ParamTypes {
    id: string
}

export const NaeRestProvider = <T extends IDict>(props: Props<T>) => {
    const NaeRestContext = createStateContext<T>();

    const getId = () => props.id ? props.id : (routeParams.id ? routeParams.id : 'new');
    const routeParams = useParams<ParamTypes>();

    const {children, moduleName, onSave, defElement} = props;
    const {isPopup} = useNaePopup();
    const history = useHistory();
    const [element, setElement] = useState<T>(defElement);
    const [id, setId] = useState(getId());
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => setId(getId), [props.id, routeParams.id]);

    const restDoSave = (options: any) => {
        setIsSaving(true);
        if (id === 'new') {
            ApiRest.addElement(
                moduleName,
                options
            ).then(res => {
                setIsSaving(false);
                toast.success('Elementas išsaugotas');
                if (onSave) {
                    onSave(res, id);
                }
                if (!isPopup) {
                    history.replace('/' + moduleName + '/' + res.id);
                }
                setElement(res);
            })
        } else {
            ApiRest.updateElement(
                moduleName,
                parseInt(id, 10),
                options
            ).then(res => {
                setIsSaving(false);
                toast.success('Elementas išsaugotas');
                if (onSave) {
                    onSave(res, id);
                }
                setElement(res);
            });
        }
    }

    const restDoSaveWithKeys = (keys: string[], extra: any = {}) => {
        const _el = {...element, ...extra}
        const dataToSave: any = {};
        keys.map((k) => {
            // @ts-ignore
            dataToSave[k] = _el[k];
        });
        restDoSave(dataToSave);
    }

    const updateElement = (key: string, val: string) => {
        const _el = JSON.parse(JSON.stringify((element)));
        _el[key] = val;
        setElement(_el);
    }

    const getData = () => {
        if (id !== 'new') {
            ApiRest.getElement(moduleName, parseInt(id, 10)).then(res => {
                setElement(res);
            }).catch(e => {

            });
        }
    }

    useEffect(getData, [id]);

    return (
        <NaeRestContext.Provider value={{id, restDoSave, restDoSaveWithKeys, element, updateElement, moduleName, isSaving, setIsSaving}}>
            {children}
        </NaeRestContext.Provider>
    )
};
